<template>
  <div class="page-container page-customType">
    <div class="operations">
      <div class="operation-btns">
        <div @click="goAdd">+新建商品组</div>
      </div>
    </div>

    <!--TABLE-->
    <el-table
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%;margin-bottom: 20px;"
    >
      <el-table-column label="序号"
                       type="index"
                       width="50">
      </el-table-column>
      <el-table-column
          prop="name"
          align="center"
          label="名称"
      >
      </el-table-column>
      <el-table-column
          prop="createTime"
          align="center"
          label="创建时间"
      >
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          width="200">
        <template slot-scope="scope">
          <div style="text-align: center;">
             <el-button class="checkCard" type="text" size="medium" @click="goEdit(scope.row)">编辑</el-button>
            <el-button class="checkCard" type="text" size="medium" @click="handleDel(scope.row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="pageNum"
          :page-sizes="[10]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog :title="title" :append-to-body='true' :visible.sync="dialogTableVisible" @close="closeDialog">
      <el-form :model="groupForm" :rules="rules" ref="groupForm" label-width="80px" class="demo-ruleForm">
        <el-form-item label="名称" prop="name">
          <el-input style="width: 50%" v-model="groupForm.name"></el-input>
        </el-form-item>
        <el-form-item label="已选商品" prop="goodIds" v-if="multipleSelection && multipleSelection.length >0">
          <el-tag
              v-for="tag in multipleSelection"
              :key="tag.goodsId"
              closable
              type="success"
          style="margin-right: 10px;"
              @close="handleCloseTag(tag)"
          >
            {{tag.goodsName}}
          </el-tag>
        </el-form-item>
        <el-form-item label="已选商品" prop="goodIds" v-else>
          <span>未选择</span>
        </el-form-item>
        <el-table ref="goodsTable" :data="goodsData" height="400" border @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column property="goodsName" align="center" label="商品名称"></el-table-column>
          <el-table-column property="goodsName" align="center" label="商品图片">
            <template slot-scope="scope">
              <el-image
                  style="width: 80px; height: 80px"
                  :src="scope.row.img"
                  fit="fit"></el-image>
            </template>
          </el-table-column>
          <el-table-column property="stockNum" align="center" label="库存"></el-table-column>
          <el-table-column property="price" align="center" label="价格(元)">
            <template slot-scope="scope">
              {{scope.row.price/100}}
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="addGoodGroup">确定</el-button>
      </div>
    </el-dialog>
  </div>

</template>
<script>
import {get, post,deletes} from "../../utils/request";
import {baseImgUrl} from "../../utils/constant";
import {saveGoodGroup} from "../../api/management";

export default {
  data() {
    return {
      multipleSelection:[],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      title: '',
      dialogTableVisible: false,
      goodsData: [],
      baseImgUrl: baseImgUrl,
      groupForm: {
        goodIds: [],
        id: null,
        name: null,
      },
      rules: {
        goodIds: [
          { required: true, message: '请选择商品', trigger: 'blur' },
        ],
        name:[
          { required: true, message: '请填写名称', trigger: 'blur' },
        ]
      }
    };
  },
  created() {

  },
  mounted() {
    this.init()
  },
  methods: {
    closeDialog(){
      this.multipleSelection = []
      this.$refs.groupForm.resetFields();
      this.dialogTableVisible = false
    },
    handleCloseTag(tag){
      this.multipleSelection.splice(this.multipleSelection.indexOf(tag),1)
      this.goodsData.forEach(item => {
        // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
        if (item == tag) {
          this.$refs.goodsTable.toggleRowSelection(tag, false);
        }
      })
    },
     addGoodGroup() {
      let arr = []
      this.multipleSelection.forEach(item => {
        arr.push(item.goodsId)
      })
      this.groupForm.goodIds = arr
      this.$refs.groupForm.validate((valid) => {
        if (valid) {
          post("/good/group",this.groupForm).then(response=>{
            //if (response.code == 200){
              this.$message.success("成功")
              this.dialogTableVisible = false
              this.init()
            //}
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    init() {
      let params = {
        companyId: JSON.parse(localStorage.getItem('info')).companyId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
      get("/good/group/list", params).then(res => {
        if (res.list) {
          this.tableData = res.list
          this.total = res.total
        }
      })
    },
    handleCurrentChange(page) {
      this.pageNum = page
      this.init()
    },
    goAdd() {
      let params = {
        pageNum: 1,
        pageSize: 10000,
      }
      this.title = "新建商品组"
      get("/goods/effectiveGood", params).then(response => {
        if (response.list) {
          this.goodsData = response.list
          this.goodsData.forEach(item => {
            item.img = this.baseImgUrl + item.imgList.split(",")[0]
          })
          this.total = response.total
          this.dialogTableVisible = true
        }
      })

    },
    goEdit(row) {
      this.title = "编辑商品组"
      let param = {
        pageNum:1,
        pageSize:10000,
        groupId:row.id
      }
      get("/goods/goodGroupInfo",param).then(res=>{
        if (res){
          let goodId = []
          this.multipleSelection = []
          this.goodsData = res.list
          this.goodsData.forEach(item=>{
            item.img = this.baseImgUrl + item.imgList.split(",")[0]
            if (item.groupFlag){
              this.multipleSelection.push(item)
              goodId.push(item.goodsId)
              this.$nextTick( ()=>{
                this.$refs.goodsTable.toggleRowSelection(item, true); // 注意这里寻找的字段要唯一
              })
            }
          })
          this.groupForm.goodIds = goodId
          this.groupForm.name = row.name
          if(row.id) {
            this.groupForm.id = row.id
          }
          this.dialogTableVisible = true
        }
      })
    },
    handleDel(row) {
      this.$confirm('确认要删除吗?', '提示', {
        type: 'warning'
      }).then(() => {
        deletes ("/good/group/del",{id:row.id}).then(res => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.init()
        })
      }).catch(() => {
        console.log('取消')
      });
    }
  }
}
</script>
<style scoped lang="scss">
.page-container {
  background: #fff;
}

.operations {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;

  .operation-btns {
    display: flex;

    div {
      border-radius: 4px;
      // background: #B9EBEA;
      background: #51CDCB;
      font-size: 14px;
      line-height: 30px;
      height: 30px;
      color: #fff;
      padding: 0 20px;
      margin-right: 10px;
      cursor: pointer;
    }

    .disabled {
      background: #B9EBEA;
    }
  }

  .search-user {
    display: flex;

    .search-input {
      height: 30px;
      margin-right: 10px;

      .el-input__inner {
        height: 30px;
      }
    }

    .search-btn {
      border-radius: 4px;
      // background: #B9EBEA;
      background: #51CDCB;
      font-size: 14px;
      line-height: 30px;
      height: 30px;
      color: #fff;
      padding: 0 20px;
      cursor: pointer;
    }

    .disabled {
      background: #B9EBEA;
    }
  }
}
.page-container[data-v-429c861e] {
  background: #fff;
  padding: 20px;
}
</style>
